import styled, { createGlobalStyle, DefaultTheme } from "styled-components";
import bgWhite from "../../assets/images/bg_white.png";
import { device, fontCopy, MainTheme } from "./theme";

/**
 * Global styles let you style outer tags like html or body.
 * As a general rule, try to keep global styles to a minimum since these
 * can silently affect any other styles.
 * https://styled-components.com/docs/api#createglobalstyle
 */
export const GlobalStyles = createGlobalStyle<{ theme: DefaultTheme }>`

  html {
  }
`;

/**
 * Styled components let you define styles for them or any children.
 * https://styled-components.com/docs/basics
 */

export const StyledLayout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  font-size: 1.6rem;
  margin-top: 8rem;
  background: linear-gradient(45deg, #1e3cb5 0%, #1e3cb5 10%, #32c8ff 60%, #32c8ff 100%);

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 35%;
    bottom: 0;
    left: 50%;
    background: url(${bgWhite});
    background-size: 200% auto;
    transform: translate(-50%, 0);

    @media ${device.minWidth768} {
      background-size: 100% auto;
    }
  }

  /* You can set some global styles for children elements here */

  p {
    margin: 1rem 0;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    &:visited {
    }
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  max-width: 123rem;
  padding: 4rem 2.8rem 3rem;
  margin: 0 auto;

  @media ${device.minWidth768} {
    padding: 0 3.2rem;
  }
`;

export const Link = styled.button`
  ${fontCopy}
  font-size: 13px;
  letter-spacing: 0.8px;
  color: ${MainTheme.colorMidnightBlue};
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: ${MainTheme.colorTransition};

  @media ${device.minWidth768} {
    font-size: 16px;
  }

  span {
    margin-left: 1rem;
    transition: transform 0ms ease;
  }

  &:hover {
    color: ${MainTheme.colorElectricBlue};
  }

  ${(props): string =>
    props.theme.isRTL
      ? `
        span {
          margin-left: initial;
          transition: transform 0ms ease;
          margin-right:1rem;
        }
      `
      : ``}
`;
