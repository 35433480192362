/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import RotateDeviceBackground from "../../assets/images/rotate-your-device.jpg";
import Locale from "../../data/contexts/riotLocale";
import { isBCPLocale } from "../../helpers/rioti18n";
import { stripSlashes } from "../../utils/string";
import { isValidUrl, stripLocale } from "../../utils/url";
import Icon from "../General/Icon/Icon";
import { NormalizeStyles } from "./normalize";
import { GlobalStyles, StyledLayout } from "./style";

const MainWrapper = styled.main`
  position: relative;
`;

const RotateYourDevice = styled.div`
  display: none;
  @media screen and (max-width: 830px) and (orientation: landscape) {
    display: block;
    position: fixed;
    top: 4rem;
    right: 0;
    bottom: 0;
    left: 0;
    background: #141e37;
    z-index: 3;

    .background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    span {
      position: absolute;
      top: calc(50% + 2rem);
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Layout: React.FC = ({ children }) => {
  const locale = useContext(Locale);
  // You can use static data from siteMetadata
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  useEffect(() => {
    const hasUrlLocale = isValidUrl();

    const urlLocale = hasUrlLocale && stripSlashes(hasUrlLocale[0]);

    if (typeof window !== undefined && !hasUrlLocale) {
      window.location.replace(`/${locale}${window.location.pathname}`.replace(/\/{2,}$/, "/"));
    } else if (urlLocale && !isBCPLocale(urlLocale)) {
      window.location.replace(`/${locale}/${stripLocale(window.location.pathname)}`.replace(/\/{2,}$/, "/"));
    } else if (typeof window !== undefined && window.location.href.match(/\/{2,}$/)) {
      window.location.replace(window.location.href.replace(/\/{2,}$/, "/"));
    }
  }, [locale]);

  return (
    <>
      {/*
        NormalizaStyles is a set of global styles that reset browser assumptions
        so we can start from an homogeneous base.
      */}
      <NormalizeStyles />
      {/* Global styles for html, body, etc. */}
      <GlobalStyles />
      <StyledLayout>
        <MainWrapper>{children}</MainWrapper>
        <RotateYourDevice>
          <img className={`background`} src={RotateDeviceBackground} alt="Rotate your device" />
          <Icon name="RotateDevice" size={150} />
        </RotateYourDevice>
      </StyledLayout>
    </>
  );
};

export default Layout;
