import { ReactComponent as Check } from "../../../assets/svg/check.svg";
import { ReactComponent as CopyLink } from "../../../assets/svg/copy-link.svg";
import { ReactComponent as Diamond } from "../../../assets/svg/diamond.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/facebook.svg";
import { ReactComponent as Helm } from "../../../assets/svg/helm.svg";
import { ReactComponent as LoaderFrame } from "../../../assets/svg/loader-frame.svg";
import { ReactComponent as Restart } from "../../../assets/svg/restart.svg";
import { ReactComponent as RotateDevice } from "../../../assets/svg/rotate-device.svg";
import { ReactComponent as Share } from "../../../assets/svg/share.svg";
import { ReactComponent as Twitter } from "../../../assets/svg/twitter.svg";

export const icons = {
  Share,
  Restart,
  Facebook,
  Twitter,
  CopyLink,
  LoaderFrame,
  Helm,
  Diamond,
  Check,
  RotateDevice,
} as const;
