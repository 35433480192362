import React, { useMemo } from "react";
import styled from "styled-components";
import { icons } from "./icons";
import { device } from "../../Layout/theme";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: number;
  name: keyof typeof icons;
}

const StyledIcon = styled.span`
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: inline-block;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: currentColor;
  }
`;

const Icon: React.FunctionComponent<Props> = ({ name, size }) => {
  const dimensions = useMemo(() => (size ? { width: size, height: size } : undefined), [size]);

  const selectedIcon = useMemo(() => {
    const Icon = icons[name];
    return !Icon ? null : <Icon />;
  }, [name]);

  return !selectedIcon ? null : <StyledIcon style={dimensions}>{selectedIcon}</StyledIcon>;
};

export default Icon;
